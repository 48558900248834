<template>
  <h1>
    Hallo!
  </h1>
</template>

<script>
export default {
  name: 'TableV2',
};
</script>

<style scoped>

</style>